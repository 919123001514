<template>
  <div class="base-background" style="min-height: 100vh;">
    <div class="d-flex justify-content-center">
      <a-row :style="{ paddingTop: '50px' }" class="outer-card align-items-center d-flex flex-column" style="min-height: 100vh;">
          <a-col class="header-card w-100">
            <div :style="{
              backgroundColor: dataGraduationLetter.id ? 'rgba(82, 196, 26, 0.15)' : 'rgba(255, 0, 0, 0.15)', // '92, 199, 40, 0.2'
              padding: '40px 30px',
            }" class="cardValidation d-flex justify-content-center flex-column text-center">
              <div>
                <div class="font-size-18">Status Validasi Surat Keterangan Lulus (SKL)</div>
                <div class="font-size-18">{{dataSkl.nama_sekolah || (institusi.nama || '').toUpperCase()}}</div>
              </div>
              <div class="d-flex align-items-center justify-content-center font-size-24 my-4">
                <template v-if="dataGraduationLetter.id">
                  <a-icon type="check-circle" theme="twoTone" two-tone-color="#52c41a" />
                  <div class="ml-2" style="color: #52c41a;">VALID</div>
                </template>
                <template v-else>
                  <a-icon type="close-circle" theme="twoTone" two-tone-color="red" />
                  <div class="ml-2" style="color: #FF0000;">TIDAK VALID</div>
                </template>
              </div>
              <div v-if="dataGraduationLetter.id">
                <div class="font-size-18 text-capitalize">{{dataMurid.nama}}</div>
                <div class="">Nomor: {{dataSkl.nomor_skl}}</div>
              </div>
              <div class="d-flex align-items-center justify-content-center" v-else>
                <div class="font-size-12 w-50">Tidak valid artinya data Surat Keterangan Lulus (SKL) terkait tidak ditemukan pada database/belum dipublikasikan. Harap menghubungi {{dataSkl.nama_sekolah || (institusi.nama || '').toUpperCase()}} untuk dapat memastikan.</div>
              </div>
            </div>
          </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import config from '@/config'

// const dummy = {
//   id: 1,
//   data_murid: {
//     nis: 91919001,
//     ttl: 'Jakarta, 30 Mei 2007',
//     nama: 'Abdullah Sami Assaqqaf',
//     nisn: '0072924429',
//     kelas: 'IX-3',
//     // id_kelas: 46,
//     // id_murid: 185,
//     nomor_peserta: 2226760044,
//     // id_tahun_ajaran: 3,
//     // id_kelas_tahun_ajaran: 761,
//   },
//   data_skl: {
//     nomor_skl: '421.308/KET.03/SMP-AIS/VI/2022',
//     tanggal_skl: '15 Juni 2022',
//     nama_sekolah: 'SMP AL BAYAN ISLAMIC SCHOOL',
//     nama_kepsek: 'Ade Badar Taufiq, ST',
//     tahun_ajaran: '2021/2022',
//   },
// }

export default {
  components: {
  },
  data() {
    return {
      configSchool: '',
      generalReport: {},
      verificationData: {
        homeroomName: null,
        homeroomDate: null,
        principalName: null,
        principalDate: null,
      },
      versionReport: null,
      dataGraduationLetter: {},
    }
  },
  methods: {
    moment,
    async fetchDataGraduationLetter() {
      const uuid = this.$route.params?.uuid
      if (uuid) {
        this.dataGraduationLetter = await this.$store.dispatch('user/FETCH_GRADUATION_LETTER', { uuid })
      }
      // console.log(this.verificationData)
    },
  },
  mounted() {
    this.configSchool = config.school
    this.fetchDataGraduationLetter()
  },
  computed: {
    dataMurid() {
      return this.dataGraduationLetter?.data_murid || {}
    },
    dataSkl() {
      return this.dataGraduationLetter?.data_skl || {}
    },
    institusi() {
      return this.$store.state.master.institusi
    },
  },
}
</script>

<style lang="scss">
/* .card-full {
  padding: 20px 20px;
  padding-top: 60px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #0419301a;
}
.detail {
  padding: 20px;
} */
.base-background {
  margin-top: 0 !important;
  background-color: whitesmoke;
  /* padding-bottom: 100px; */
  .cardValidation {
    border-radius: 6px;
    width: 100%;
  }
}
.outer-card {
  width: 100%;
  @media (min-width: 768px) {
    width: 75%;
  }
  .col-report {
    @media (max-width: 768px) {
      overflow: scroll;
      width: 100%;
    }
    .content-report {
      @media (max-width: 768px) {
        overflow: scroll;
        min-width: 800px;
      }
    }
  }
}
/* .header-card {
  background-color: whitesmoke;
  padding-top: 50px;
  padding-bottom: 50px;
} */
</style>
